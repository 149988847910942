import { type ComponentPropsWithoutRef, useRef } from "react"
import "./InfoIconTooltip.scss"
import { useTooltip } from "../../../../hooks/useTooltip"
import { mergeClasses } from "../../../../utils/css"
import { Info } from "../../atoms/Icons/Others"
import { Tooltip } from "../../atoms/Tooltip"

interface InfoIconTooltipProps extends ComponentPropsWithoutRef<"div"> {
    text: string
    tooltipClassName?: string
}

export function InfoIconTooltip({
    text,
    tooltipClassName,
    className,
    ...props
}: InfoIconTooltipProps) {
    const ref = useRef(null)
    const { isOpen } = useTooltip(ref)
    return (
        <div
            className={mergeClasses("cb-info-icon-tooltip", className)}
            {...props}
        >
            <Info ref={ref} />
            {isOpen && (
                <>
                    <Tooltip
                        text={text}
                        className={mergeClasses(
                            "cb-info-icon-tooltip__ttcontent",
                            tooltipClassName,
                        )}
                        divotProps={{
                            direction: "left",
                            top: "-2px",
                            left: "15px",
                            divotBgTop: "1px",
                            divotBgLeft: "1px",
                        }}
                    />
                </>
            )}
        </div>
    )
}
