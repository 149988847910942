import { createSlice } from "@reduxjs/toolkit"
import { createApi } from "@reduxjs/toolkit/query/react"
import { cbBaseQuery } from "../../utils/myFetch"

export const notesApi = createApi({
    baseQuery: cbBaseQuery({
        baseUrl: "/api/notes/",
    }),
    tagTypes: ["Note"],
    refetchOnMountOrArgChange: 30, // Cache for 30 seconds
    refetchOnFocus: true,
    endpoints: (build) => ({
        getNotesUsernames: build.query<{ usernames: string[] }, void>({
            query: () => ({
                url: "usernames/",
                method: "GET",
            }),
        }),
        getNoteForUser: build.query<string, { username: string }>({
            query: ({ username }) => ({
                url: `for_user/${username}/`,
                method: "GET",
            }),
            transformResponse: (response: { text: string }) => {
                return response.text ?? ""
            },
            providesTags: ["Note"],
        }),
        saveNoteForUser: build.mutation<
            { success: boolean },
            { username: string; note: string | undefined }
        >({
            query: (arg) => ({
                url: `for_user/${arg.username}/`,
                method: "POST",
                data: {
                    text: arg.note ?? "",
                },
            }),
            async onQueryStarted(
                { username, note },
                { dispatch, queryFulfilled },
            ) {
                try {
                    const { data: updatedPost } = await queryFulfilled
                    // Updates the cache as well if server update was successful
                    if (updatedPost.success) {
                        dispatch(
                            notesApi.util.updateQueryData(
                                "getNoteForUser",
                                { username },
                                () => note ?? "",
                            ),
                        )
                    }
                } catch {}
            },
        }),
    }),
})

interface NotesState {
    notesCache: Record<string, string>
    notesEdited: Record<string, boolean>
}

const initialState: NotesState = {
    notesCache: {},
    notesEdited: {},
}

const notesSlice = createSlice({
    name: "notes",
    initialState: initialState,
    reducers: {
        setNoteCache: (state, action) => {
            state.notesCache[action.payload.username] =
                action.payload.note ?? ""
            state.notesEdited[action.payload.username] = true
        },
        trimNoteCache: (state, action) => {
            state.notesCache[action.payload.username] =
                state.notesCache[action.payload.username]?.trim()
        },
    },
    extraReducers(builder) {
        builder.addMatcher(
            notesApi.endpoints.getNoteForUser.matchFulfilled,
            (state, action) => {
                if (!state.notesEdited[action.meta.arg.originalArgs.username]) {
                    state.notesCache[action.meta.arg.originalArgs.username] =
                        action.payload ?? ""
                }
            },
        )
        builder.addMatcher(
            notesApi.endpoints.saveNoteForUser.matchPending,
            (state, action) => {
                state.notesEdited[action.meta.arg.originalArgs.username] = false
            },
        )
    },
})

export const { setNoteCache, trimNoteCache } = notesSlice.actions
export const { useGetNoteForUserQuery, useSaveNoteForUserMutation } = notesApi

export default notesSlice.reducer
