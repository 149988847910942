import React from "react"
import { mergeClasses } from "../../../../utils/css"
import "./Flex.scss"

interface FlexProps
    extends React.ComponentPropsWithRef<React.ElementType<any>> {
    /**
     * Children passed to the `Flex` component will be implicitly flexbox items
     */
    children: React.ReactNode
    /**
     * `component` is either a string (for intrinsic html elements, like "div" or a react component. By default
     * a <div> is rendered.
     */
    component?: React.ElementType<any>
    /**
     * Some classes are written by default, but you can provide more via this prop.
     */
    className?: string
    /**
     * Same as css's flex-direction. Sets a utility class, defaults to `row` implicitly
     */
    direction?: "row" | "row-reverse" | "column" | "column-reverse"
    /**
     * Intentionally limited alignment (main-axis) prop. If you need the other possible values, set a class in your component.
     */
    align?: "start" | "center" | "end"
    /**
     * Intentionally limited justify (cross-axis) prop. Defaults to start
     */
    justify?: "start" | "center" | "end"
    /**
     * Whether to wrap or not. Defaults to wrapping.
     */
    wrap?: boolean
}

/**
 * `Flex` is a component for defining a flexbox container with some helpful props for controlling common
 * layout needs. The main params are `direction`, `align`, and `wrap`.
 *
 * Note that there is no matching `FlexItem` component, and the intent is that components style flex items
 * via classes on other components, rather than wrapping them in a hypothetical `FlexItem` container.
 */
export const Flex = React.forwardRef<React.ElementType<any>, FlexProps>(
    (
        {
            children,
            component = "div",
            className,
            direction = "row",
            align = "start",
            justify = "start",
            wrap = true,
            ...props
        },
        ref,
    ) => {
        const Component = component
        const classList = [
            "Flex",
            `Flex--${direction}`,
            `Flex-align--${align}`,
            `Flex-justify--${justify}`,
            `Flex--${wrap ? "wrap" : "nowrap"}`,
        ]

        return (
            <Component
                className={mergeClasses(classList, className)}
                ref={ref}
                {...props}
            >
                {children}
            </Component>
        )
    },
)
