import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgLitecoin = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 19 19"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#A5A8A9"
            fillRule="evenodd"
            d="M18.999 9.5a9.5 9.5 0 1 1-19 0 9.5 9.5 0 0 1 19 0m-11.817 0 1.39-5.214h3.013l-1.043 4.055 1.39-.579-.347 1.274-1.39.464-.696 2.549h4.982l-.463 1.853H6.023l.812-3.128-1.159.464.347-1.275z"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgLitecoin)
const Memo = memo(ForwardRef)
export default Memo
