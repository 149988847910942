import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgCreditcardgeneric = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 14 10"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#CBCBCB"
            d="M.143 8.776A1.193 1.193 0 0 0 1.335 9.97h11.33a1.19 1.19 0 0 0 1.192-1.193V3.708a.15.15 0 0 0-.149-.149H.292a.15.15 0 0 0-.15.15zM9.98 7.435h1.79a.447.447 0 0 1 0 .894H9.98a.447.447 0 1 1 0-.894M2.528 5.05h4.77a.447.447 0 1 1 0 .894h-4.77a.447.447 0 0 1 0-.894m0 2.385h1.789a.447.447 0 0 1 0 .894h-1.79a.447.447 0 1 1 0-.894M13.857 1.621A1.19 1.19 0 0 0 12.665.43H1.335A1.193 1.193 0 0 0 .143 1.62v.895a.15.15 0 0 0 .149.149h13.416a.15.15 0 0 0 .15-.15z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgCreditcardgeneric)
const Memo = memo(ForwardRef)
export default Memo
