import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgSafetyPay = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 399 91"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path fill="#fff" d="M0 0h399v91H0z" />
        <path
            fill="#009dd4"
            d="m237.16 6.99.51.06c11.63 11.78 23.57 23.3 35.03 35.22-2.35.25-4.72.31-7.08.23-6.52-6.71-13.38-13.07-19.96-19.71-1.45 1.29-2.8 2.68-4.13 4.08 5.46 5.62 11.12 11.03 16.59 16.64-2.42-.3-4.82.11-6.97 1.26-7.21-7.13-14.31-14.37-21.51-21.51-3.86 3.74-7.57 7.61-11.43 11.35 2.37 2.93 5.35 5.31 7.87 8.12-3.37-.49-6.76-.18-10.06.59-4.83.19-9.74.42-14.41-1.01 11.66-11.97 23.76-23.48 35.55-35.32"
        />
        <path
            fill="#fff"
            d="M241.53 26.87c1.33-1.4 2.68-2.79 4.13-4.08 6.58 6.64 13.44 13 19.96 19.71 1.3 1.26 2.59 2.54 3.88 3.82-7.9 7.99-15.89 15.89-23.82 23.85-1.51-1.28-2.87-2.74-4.23-4.17 6.36-6.7 13.22-12.88 19.62-19.54-.98-.99-1.98-1.95-2.95-2.95-5.47-5.61-11.13-11.02-16.59-16.64"
        />
        <path
            fill="#fff"
            d="M218.21 34.61c3.86-3.74 7.57-7.61 11.43-11.35 7.2 7.14 14.3 14.38 21.51 21.51.49.5 1.47 1.49 1.96 1.98-7.94 7.71-15.51 15.93-23.66 23.34-3.68-3.79-7.49-7.44-11.15-11.24 3.95-4 7.99-7.92 11.9-11.97-1.33-1.43-2.77-2.74-4.12-4.15-2.52-2.81-5.5-5.19-7.87-8.12"
        />
        <path
            fill="#035eaa"
            d="M11.44 33.53c4.72-2.14 10.19-1.82 15.18-.94 4.35.8 7.19 4.92 7.41 9.18-3.24-.01-6.48-.06-9.72.03-.13-4.21-8.01-5.62-8.28-.98 4.13 3.2 10.43 2.18 14.8 5.36 5.57 2.98 5.37 11.58.61 15.26-5.14 4.01-12.37 3.48-18.4 2.27-4.68-1.06-7.21-5.73-7.75-10.18 3.19-.65 6.54-.11 9.79-.28.24 4.69 7.28 6.87 9.75 2.77 1-2.4-1.79-3.78-3.66-4.26-4.58-1.19-9.95-1.87-13.15-5.76-3.23-4.05-1.12-10.41 3.42-12.47M46.39 32.11c3.35-.14 6.78-.26 10.1.06 3.44 10.8 7.23 21.49 10.62 32.3-3.39.05-6.79.06-10.18 0-.44-1.74-.86-3.49-1.39-5.21-2.74 0-5.48-.06-8.22.04-.48 1.73-.84 3.49-1.42 5.2-3.4.01-6.79.01-10.19-.01 3.52-10.81 7.31-21.53 10.68-32.38M69.51 32.01c9.24-.01 18.49-.02 27.73.01.03 2.32.02 4.64 0 6.96-5.83.05-11.65-.02-17.47.03-.04 1.8-.01 3.59-.03 5.39 4.96.22 9.95.06 14.91.07.23 2.22.04 4.46.11 6.7-4.96.2-9.94.03-14.91.08-.23 4.39-.03 8.82-.09 13.22-3.42.07-6.83.03-10.25.02-.02-10.83-.02-21.65 0-32.48M101.76 32.01c9.24-.02 18.49-.01 27.73 0 .02 2.33.03 4.66 0 6.98-5.83.03-11.65-.02-17.48.02-.01 1.83-.08 3.68.06 5.51 3.81-.03 7.61-.04 11.42-.01.02 2.25.03 4.49-.01 6.73-3.82.03-7.64-.01-11.45.01-.06 2-.04 3.99-.02 5.98 5.82.03 11.63.03 17.44 0 .11 2.42.04 4.84.04 7.26-9.25.01-18.49.03-27.74-.01 0-10.82-.02-21.64.01-32.47M133.45 32.34l.34-.33c9.99.01 19.98-.05 29.97.03-.01 2.31 0 4.62-.01 6.93-3.41.23-6.96-.31-10.29.26.09 8.42.03 16.84.03 25.26-3.33.02-6.66.02-9.98 0 0-8.45-.05-16.9.03-25.35-3.42-.36-6.88.02-10.32-.2-.11-2.21.51-4.38.23-6.6M165.85 31.77c3.34-.05 6.69-.03 10.03-.01 2.07 3.27 3.7 6.79 5.48 10.23l.61.01c1.81-3.41 3.61-6.82 5.41-10.24 3.35 0 6.71-.07 10.06.05-3.52 6.38-7.19 12.68-10.68 19.08-.06 4.54.08 9.09-.07 13.63-3.4-.05-6.79.01-10.18-.03-.1-4.66.12-9.33-.1-13.98-3.55-6.23-6.99-12.52-10.56-18.74"
        />
        <path
            fill="#009dd4"
            d="M284.25 32.04c6.63.01 13.3-.19 19.91.11 5.87.58 8.95 7.03 7.56 12.36-3.49.19-6.99.23-10.47-.1.47-1.4.93-3.13-.22-4.34-1.71-1.91-4.5-1.23-6.76-1.3l-.04 2.94c-2.78.28-5.56.12-8.34.11-.23 1.96-.62 3.89-1.59 5.63-.18-5.13.03-10.27-.05-15.41M320.3 32.08c3.29-.18 6.58-.04 9.87-.08 1.21 3.53 2.45 7.06 3.5 10.64-2.67.19-5.36.3-8-.22l-.62-.09c-.35 1.4-.58 2.84-.9 4.25a95 95 0 0 0-8.59-.02c1.47-4.86 3.28-9.62 4.74-14.48M339.11 32.04c3.35-.05 6.7-.05 10.05-.02 1.91 3.25 3.46 6.71 5.35 9.98 1.26-.13 1.33-1.8 2.03-2.6 1.21-2.48 2.6-4.86 3.79-7.34 3.39-.15 6.79.01 10.19-.06-3.01 5.16-5.75 10.47-8.81 15.58-4.41.1-9.27-1.25-13.34.95l-.13.06c-2.94-5.57-6.12-11.02-9.13-16.55M371.85 32.98c3.05-1.7 6.75-.02 10.06-.75.38 1.05.71 2.12 1 3.2.29-1.05.59-2.1.89-3.16l2.72.32c-.09 2.13.12 4.28-.18 6.4-2.47.16-1.34-2.33-1.85-3.73-.67 1.29.05 4.34-2.32 3.54-.27-1.17-.55-2.34-.83-3.51-.3 1.36.35 4.31-2.02 3.5-.08-1.78-.18-3.59.1-5.36-.67.2-2.03.59-2.7.78-.01 1.52.06 3.05-.02 4.57-.56-.03-1.68-.09-2.24-.13.01-1.45.05-2.9.13-4.35-.68-.33-2.05-.99-2.74-1.32"
        />
        <path
            fill="#fff"
            d="M294.27 38.77c2.26.07 5.05-.61 6.76 1.3 1.15 1.21.69 2.94.22 4.34-1.96 1.63-4.62 1.38-6.99 1.35-.06-1.35-.03-2.7-.03-4.05z"
        />
        <path
            fill="#035eaa"
            d="M285.89 41.82c2.78.01 5.56.17 8.34-.11 0 1.35-.03 2.7.03 4.05 2.37.03 5.03.28 6.99-1.35 3.48.33 6.98.29 10.47.1-.6 3.54-3.35 6.68-6.92 7.42-3.41 1.15-7.16.15-10.56.78.02 3.93.03 7.86 0 11.78-3.31.06-6.62-.01-9.93.04-.15-5.69-.08-11.39-.01-17.08.97-1.74 1.36-3.67 1.59-5.63"
        />
        <path
            fill="#fff"
            d="M51.29 41.34c1.01 3.49 1.59 7.1 2.36 10.65-1.09 0-3.26.01-4.35.01.6-3.57 1.4-7.09 1.99-10.66"
        />
        <path
            fill="#035eaa"
            d="M265.62 42.5c2.36.08 4.73.02 7.08-.23h.13c1.37 1.34 2.72 2.7 4.1 4.03l-.01.4c-13.07 13.12-26.19 26.19-39.27 39.3h-.52c-13.16-13.2-26.53-26.14-39.68-39.35 1.17-1.62 2.78-2.89 4.16-4.34 4.67 1.43 9.58 1.2 14.41 1.01 3.3-.77 6.69-1.08 10.06-.59 1.35 1.41 2.79 2.72 4.12 4.15-3.91 4.05-7.95 7.97-11.9 11.97 3.66 3.8 7.47 7.45 11.15 11.24 8.15-7.41 15.72-15.63 23.66-23.34-.49-.49-1.47-1.48-1.96-1.98 2.15-1.15 4.55-1.56 6.97-1.26.97 1 1.97 1.96 2.95 2.95-6.4 6.66-13.26 12.84-19.62 19.54 1.36 1.43 2.72 2.89 4.23 4.17 7.93-7.96 15.92-15.86 23.82-23.85-1.29-1.28-2.58-2.56-3.88-3.82"
        />
        <path
            fill="#fff"
            d="m325.05 42.33.62.09c.36 3.22 1.23 6.37 1.86 9.55-1.45.05-2.9.05-4.34 0 .24-1.81.54-3.61.96-5.39.32-1.41.55-2.85.9-4.25"
        />
        <path
            fill="#035eaa"
            d="M325.67 42.42c2.64.52 5.33.41 8 .22h.01c2.48 7.29 5.01 14.56 7.33 21.91-3.42-.15-6.86.13-10.26-.15-.54-1.67-.82-3.41-1.33-5.09-2.73-.13-5.47-.02-8.19-.06-.47 1.75-.97 3.49-1.42 5.24-3.39.04-6.78.05-10.16-.02 1.96-5.97 3.94-11.94 5.91-17.91 2.86-.12 5.73-.12 8.59.02-.42 1.78-.72 3.58-.96 5.39 1.44.05 2.89.05 4.34 0-.63-3.18-1.5-6.33-1.86-9.55M348.37 48.53c4.07-2.2 8.93-.85 13.34-.95-3.64 4.91-1.2 11.31-2.03 16.95-3.31-.05-6.62.02-9.92-.04-.05-4.4 0-8.81-.02-13.21-.34-.69-1.03-2.07-1.37-2.75"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgSafetyPay)
const Memo = memo(ForwardRef)
export default Memo
