import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"
import "./PurchaseIcons.scss"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgPayPal = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 17 15"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        className="paypal-icon"
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#253B80"
            fillRule="evenodd"
            d="M2.035 10.066H.772a.174.174 0 0 0-.173.144l-.51 3.158c-.01.063.039.119.103.119h.603c.087 0 .16-.061.173-.145l.138-.851a.174.174 0 0 1 .173-.145h.4c.832 0 1.312-.393 1.437-1.17.057-.34.002-.608-.161-.796-.18-.205-.498-.314-.92-.314m.145 1.153c-.069.442-.415.442-.75.442h-.19l.134-.825a.104.104 0 0 1 .104-.087h.087c.228 0 .443 0 .554.127q.1.112.061.343m3.629-.014h-.605a.105.105 0 0 0-.104.087l-.027.165-.042-.06c-.13-.185-.423-.247-.714-.247-.668 0-1.239.493-1.35 1.186-.058.345.025.675.225.906.185.211.448.3.762.3.538 0 .837-.338.837-.338l-.027.164c-.01.063.04.119.104.119h.544c.087 0 .16-.062.174-.145l.326-2.018a.103.103 0 0 0-.103-.119m-.843 1.148a.67.67 0 0 1-.682.563c-.176 0-.316-.055-.406-.159a.5.5 0 0 1-.095-.414.67.67 0 0 1 .678-.568c.171 0 .31.056.403.16a.5.5 0 0 1 .102.418m3.455-1.148h.608c.085 0 .134.093.086.161l-2.02 2.845a.18.18 0 0 1-.145.074h-.607a.103.103 0 0 1-.085-.162l.629-.866-.67-1.916a.103.103 0 0 1 .1-.136h.597c.078 0 .146.05.169.122l.355 1.157.838-1.204a.18.18 0 0 1 .145-.075"
            clipRule="evenodd"
        />
        <path
            fill="#179BD7"
            fillRule="evenodd"
            d="m15.009 13.368.518-3.216a.105.105 0 0 1 .104-.086h.583c.065 0 .114.056.104.118l-.511 3.158a.174.174 0 0 1-.174.145h-.52a.103.103 0 0 1-.104-.119m-3.968-3.302H9.778a.174.174 0 0 0-.173.144l-.51 3.158c-.011.063.038.119.103.119h.648c.06 0 .112-.043.121-.101l.145-.896a.174.174 0 0 1 .173-.144h.4c.831 0 1.311-.393 1.437-1.17q.084-.513-.161-.796c-.18-.205-.498-.314-.92-.314m.145 1.153c-.069.442-.415.442-.75.442h-.19l.134-.825a.104.104 0 0 1 .104-.087h.087c.228 0 .443 0 .554.127q.1.112.061.343m3.628-.014h-.604a.104.104 0 0 0-.104.087l-.026.165-.043-.06c-.13-.185-.422-.247-.714-.247-.668 0-1.238.493-1.35 1.186-.057.345.025.675.226.906.184.211.447.3.761.3.538 0 .837-.338.837-.338l-.027.164c-.01.063.04.119.104.119h.544c.087 0 .16-.062.174-.145l.327-2.018a.104.104 0 0 0-.105-.119m-.842 1.148a.67.67 0 0 1-.682.563c-.176 0-.316-.055-.406-.159a.5.5 0 0 1-.095-.414.67.67 0 0 1 .677-.568c.172 0 .311.056.403.16a.5.5 0 0 1 .103.418"
            clipRule="evenodd"
        />
        <path
            fill="#253B80"
            d="m6.707 8.936.155-.961-.346-.008h-1.65L6.012.87a.09.09 0 0 1 .093-.077H8.89c.924 0 1.561.187 1.894.558.157.173.256.355.304.555.05.21.052.46.002.765l-.003.022v.196l.156.086q.196.102.316.235.2.225.256.561.056.347-.036.814a2.8 2.8 0 0 1-.342.922 1.9 1.9 0 0 1-.542.579 2.2 2.2 0 0 1-.73.32 3.7 3.7 0 0 1-.911.104h-.217a.67.67 0 0 0-.423.152.64.64 0 0 0-.221.384l-.016.087-.275 1.695-.012.062q-.005.028-.017.036a.05.05 0 0 1-.029.01z"
        />
        <path
            fill="#179BD7"
            d="m11.391 2.716-.028.159c-.367 1.839-1.623 2.474-3.227 2.474h-.817a.394.394 0 0 0-.392.327l-.418 2.587-.119.734a.206.206 0 0 0 .207.235h1.448a.346.346 0 0 0 .344-.286l.015-.072.272-1.688.018-.093a.346.346 0 0 1 .344-.287h.217c1.403 0 2.502-.556 2.823-2.164.134-.672.065-1.233-.29-1.628a1.4 1.4 0 0 0-.397-.298"
        />
        <path
            fill="#222D65"
            d="M11.007 2.567a3 3 0 0 0-.357-.078 5 5 0 0 0-.72-.051H7.748a.35.35 0 0 0-.15.033.34.34 0 0 0-.194.254L6.94 5.593l-.013.084a.394.394 0 0 1 .392-.328h.817c1.604 0 2.86-.636 3.227-2.474q.015-.08.028-.16a2 2 0 0 0-.384-.148"
        />
        <path
            fill="#253B80"
            d="M7.404 2.725a.34.34 0 0 1 .194-.253.4.4 0 0 1 .15-.034H9.93q.389 0 .72.051a3 3 0 0 1 .357.078l.082.025q.164.052.302.124c.11-.68 0-1.142-.377-1.561C10.599.694 9.849.496 8.89.496H6.106a.395.395 0 0 0-.393.328l-1.16 7.17c-.022.141.09.27.237.27h1.718l.432-2.671z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgPayPal)
const Memo = memo(ForwardRef)
export default Memo
