export function getCoords(elem: HTMLElement): DOMRect {
    function firstNonZero(a: number, b: number, c?: number): number {
        return a === 0 ? (b === 0 ? (c === undefined ? 0 : c) : b) : a
    }

    const box = elem.getBoundingClientRect()

    const body = document.body
    const docEl = document.documentElement

    const scrollTop = firstNonZero(
        window.pageYOffset,
        docEl.scrollTop,
        body.scrollTop,
    )
    const scrollLeft = firstNonZero(
        window.pageXOffset,
        docEl.scrollLeft,
        body.scrollLeft,
    )

    const clientTop = firstNonZero(docEl.clientTop, body.clientTop)
    const clientLeft = firstNonZero(docEl.clientLeft, body.clientLeft)

    const top = box.top + scrollTop - clientTop
    const left = box.left + scrollLeft - clientLeft

    return new DOMRect(left, top, box.width, box.height)
}

export function convertToIntOrFallback(stringToConvert: string | null): number {
    const parsed = parseInt(stringToConvert ?? "")
    return parsed ? parsed : 0
}

export function sortByTabIndex(
    firstNode: Element,
    secondNode: Element,
): number {
    const tabIndexes: number[] = [firstNode, secondNode].map((node) =>
        getTabIndexOfNode(node),
    )
    return tabIndexes
        .map((tabIndexValue) =>
            sanitizeTabIndexInput(tabIndexValue, Math.max(...tabIndexes)),
        )
        .reduce((previousValue, currentValue) => previousValue - currentValue)
}

/**
 * Prepares a tab-index to be further processed for the tab order of the focus trap.
 * It can't be less than 0, because negative values can not be part of the tab order at all.
 * In case it's exactly 0 it actually needs to be higher than any positive (> 0) value, since tab-index=0 means "follow the system default order".
 * The default tab order comes _after_ special tab indexes (>0).
 * @param {number} tabIndex The index to sanitize
 * @param {number} highestPositiveTabIndex The largest number among the tab indexes from the same context
 * @throws An error if the tabIndex is less than 0
 * @returns Tha sanitized tab index
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex} for further information on the tabindex and its order
 */
function sanitizeTabIndexInput(
    tabIndex: number,
    highestPositiveTabIndex: number,
) {
    if (tabIndex < 0) {
        throw new Error(
            `Unable to sort given input. A negative value is not part of the tab order: ${tabIndex}`,
        )
    }
    // 0 based tab indexes have a higher order than positive valued indicies, thus we add 1 to the max value
    return tabIndex === 0 ? highestPositiveTabIndex + 1 : tabIndex
}

export function getTabIndexOfNode(targetNode: Element): number {
    return convertToIntOrFallback(targetNode.getAttribute("tabindex"))
}
