import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
    color?: string
}
const SvgChevron = (
    {
        title,
        titleId,
        color = "#306A91",
        ...props
    }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 6 10"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 1 1 5l4 4"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgChevron)
const Memo = memo(ForwardRef)
export default Memo
