import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgVisa = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 28 19"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <rect width={28} height={19} fill="#fff" rx={2.5} />
        <path
            fill="#172B85"
            fillRule="evenodd"
            d="M8.553 12.871H6.856L5.584 8.07c-.06-.22-.188-.416-.377-.508a5.6 5.6 0 0 0-1.555-.508v-.185h2.733a.71.71 0 0 1 .707.6l.66 3.464 1.695-4.064h1.65zm3.486 0h-1.602l1.32-6.003h1.601zm3.392-4.34c.047-.323.33-.508.66-.508a3 3 0 0 1 1.555.277l.283-1.293a4.1 4.1 0 0 0-1.46-.278c-1.556 0-2.687.832-2.687 1.986 0 .878.801 1.339 1.367 1.617.612.277.848.461.8.738 0 .416-.47.6-.941.6a4.1 4.1 0 0 1-1.65-.37l-.282 1.294c.565.23 1.177.324 1.743.324 1.743.045 2.827-.785 2.827-2.032 0-1.57-2.215-1.663-2.215-2.355m7.821 4.34L21.98 6.868h-1.366a.71.71 0 0 0-.66.462L17.6 12.87h1.649l.329-.877h2.026l.189.877zM20.85 8.484l.47 2.263h-1.319z"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgVisa)
const Memo = memo(ForwardRef)
export default Memo
