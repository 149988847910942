import "./CvvPage.scss"
import type { ComponentPropsWithoutRef } from "react"
import { gettext } from "@multimediallc/web-utils"
import { Button, ButtonColor, TextInput, Typography } from "../../common"

const i18n = {
    cvvText: gettext(
        "We take security very seriously. Please re-verify your card with the CVV code:",
    ),
    backLink: gettext("Back to Payment Options"),
}

interface CvvPageProps extends ComponentPropsWithoutRef<"div"> {
    onGoBack: () => void
}
export function CvvPage({ onGoBack, ...props }: CvvPageProps) {
    return (
        <div className="CvvPage" {...props}>
            <Typography
                component="label"
                size="smpx"
                className="CvvPage__cvvText"
                htmlFor="cvv"
            >
                {i18n.cvvText}
            </Typography>
            <TextInput
                name="cvv"
                placeholder="CVV Number"
                className="CvvPage__cvvInput"
                type="tel"
                inputMode="numeric"
                maxLength={3}
                minLength={3}
                id="cvv"
                required
            />
            <Button
                data-testid="cvv-back-button"
                className="CvvPage__backButton"
                color={ButtonColor.Link}
                text={i18n.backLink}
                onClick={onGoBack}
            />
        </div>
    )
}
