import React from "react"
import "./Spinner.scss"
import { Sizes } from "../../../../interfaces/sizing"
import { mergeClasses } from "../../../../utils/css"
import { default as loading } from "./loading.gif"

interface SpinnerProps extends React.ComponentPropsWithoutRef<"div"> {
    /**
     * Defines the width and height based on typography sizes, but doubled
     */
    size?: keyof typeof Sizes
}

export const Spinner = ({ className, size = "md", ...props }: SpinnerProps) => {
    const classList = `cbr-spinner cbr-spinner--${size}`

    return (
        <div className={mergeClasses(classList, className)} {...props}>
            <img src={loading} alt="Loading..." />
        </div>
    )
}
