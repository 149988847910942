import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgMastercard = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 28 19"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <rect width={28} height={19} fill="#fff" rx={2.5} />
        <g fillRule="evenodd" clipRule="evenodd">
            <path
                fill="#ED0006"
                d="M14.26 13.89a6.25 6.25 0 0 1-3.968 1.403c-3.379 0-6.118-2.628-6.118-5.87s2.74-5.87 6.118-5.87c1.515 0 2.9.528 3.969 1.403a6.25 6.25 0 0 1 3.968-1.403c3.38 0 6.119 2.628 6.119 5.87s-2.74 5.87-6.119 5.87c-1.514 0-2.9-.528-3.968-1.402"
            />
            <path
                fill="#F9A000"
                d="M14.26 13.89c1.316-1.076 2.15-2.678 2.15-4.467s-.834-3.39-2.15-4.467a6.25 6.25 0 0 1 3.97-1.403c3.378 0 6.118 2.628 6.118 5.87s-2.74 5.87-6.119 5.87a6.25 6.25 0 0 1-3.968-1.402"
            />
            <path
                fill="#FF5E00"
                d="M14.261 13.89c1.316-1.076 2.15-2.678 2.15-4.467s-.835-3.39-2.15-4.467c-1.315 1.076-2.15 2.678-2.15 4.467 0 1.79.834 3.391 2.15 4.468"
            />
        </g>
    </svg>
)
const ForwardRef = forwardRef(SvgMastercard)
const Memo = memo(ForwardRef)
export default Memo
