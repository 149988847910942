import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgJcb = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 63 47"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#1E4F8F"
            fillRule="evenodd"
            d="M8.413.923a7.5 7.5 0 0 0-3.086.454A7.03 7.03 0 0 0 .74 6.892C.54 9.043.588 13.925.588 17.214V27c.233.127.478.236.716.353q.831.391 1.7.692c2.73.982 7.446 1.459 7.844-2.635.222-3.014.015-6.111.015-9.133h6.292v7.6c0 1.868-.108 3.736-1.48 5.114a7.97 7.97 0 0 1-4.485 1.959l-.209.029a24.8 24.8 0 0 1-5.643.016 32.5 32.5 0 0 1-4.749-.8v15.85c3.831 0 8.417.014 11.59-.038a6.71 6.71 0 0 0 4.6-2.435c2.253-2.562 1.893-5.98 1.893-9.177V.918s-6.9-.004-10.259.005"
            clipRule="evenodd"
        />
        <path
            fill="#D8203B"
            fillRule="evenodd"
            d="M29.036.966a6.74 6.74 0 0 0-4.17 1.815 7.6 7.6 0 0 0-2.544 5.684v9.845c3.441-2.827 8.662-2.748 12.82-2.188a34 34 0 0 1 3.455.65v3.184a15 15 0 0 0-5.242-1.638l-.13-.008c-3.115-.312-6.364.869-6.692 4.542a6.8 6.8 0 0 0 .072 1.832 5.36 5.36 0 0 0 1.568 2.816c.439.33.918.602 1.426.81a10.7 10.7 0 0 0 5.878-.068 16.6 16.6 0 0 0 3.076-1.257v3.18c-.491.122-.976.26-1.472.364s-1.012.188-1.521.263c-.954.14-1.91.245-2.873.29-.814.039-1.624.016-2.438.016a25 25 0 0 1-2.42-.187 12 12 0 0 1-2.247-.494 13 13 0 0 1-1.735-.742 6.5 6.5 0 0 1-1.5-.982V46.06h9.944q.996.007 1.983-.121a6.2 6.2 0 0 0 1.977-.577 9.3 9.3 0 0 0 1.448-.892 7.5 7.5 0 0 0 2.151-2.5c.396-.77.654-1.604.762-2.463q.164-2.177.062-4.358V.934c-.086-.001-9.646-.02-11.638.032"
            clipRule="evenodd"
        />
        <path
            fill="#4DAD47"
            fillRule="evenodd"
            d="M47.8 22.173h-.039v-3.664h3.985a5 5 0 0 1 .36.035 1.761 1.761 0 0 1 .504.17l.073.037q.114.063.216.143.119.09.218.2.175.187.288.416c.028.055.047.11.072.168q.045.12.072.246c.077.365.052.744-.072 1.095q-.03.085-.072.166a1.7 1.7 0 0 1-.432.554q-.196.17-.436.271a2.3 2.3 0 0 1-.503.146c-1.4.11-2.83.025-4.236.025zm-.038 6.213h4.41c.026 0 .047-.005.072-.008s.047 0 .072-.005q.108-.009.215-.025l.043-.012.03-.007a.4.4 0 0 0 .071-.018q.225-.07.432-.183.11-.065.216-.138.12-.084.218-.191.038-.04.072-.078a2.4 2.4 0 0 0 .218-.3c.028-.043.047-.09.072-.133a1 1 0 0 0 .07-.176q.046-.12.072-.249c.084-.39.06-.795-.072-1.171q-.032-.085-.07-.17a2 2 0 0 0-.216-.353 1 1 0 0 0-.074-.087.5.5 0 0 0-.076-.082 1 1 0 0 0-.072-.07c-.025-.025-.047-.043-.072-.066a.5.5 0 0 0-.074-.055 1 1 0 0 0-.216-.14 2.924 2.924 0 0 0-.534-.208l-.185-.03h-.072a1 1 0 0 0-.072-.007q-.038.002-.072-.007a1 1 0 0 1-.072 0h-4.335v3.98zM50.893.934a6.73 6.73 0 0 0-4.256 1.818A7.68 7.68 0 0 0 44.221 6.9a7.8 7.8 0 0 0-.152 1.536v7.759c3.8.012 7.683-.183 11.468.1 1.646.121 3.431.9 3.861 2.685q.051.217.072.439.033.358 0 .715a3 3 0 0 1-.144.685 3.85 3.85 0 0 1-3.24 2.418v.083c0 .015.762.128.832.139a4.32 4.32 0 0 1 3 1.836 3.29 3.29 0 0 1 .35 2.523 4.01 4.01 0 0 1-3.2 2.732 8 8 0 0 1-1.068.157c-.739.064-1.48.1-2.222.124-1.568.049-3.137.022-4.7-.017-1.656-.041-3.31-.1-4.967-.1v15.345h10.892a7.8 7.8 0 0 0 2.042-.284 7.87 7.87 0 0 0 3.773-2.565 7.5 7.5 0 0 0 1.55-3.426q.045-.282.072-.572c.09-4.339.03-8.688.03-12.968V.924s-7.818-.009-11.578.01z"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgJcb)
const Memo = memo(ForwardRef)
export default Memo
