import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgDiscover = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 28 19"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <rect width={28} height={19} fill="#fff" rx={2.5} />
        <path
            fill="#FD6020"
            d="m11.383 17.852 15.42-4.552v3.002a1.55 1.55 0 0 1-1.55 1.55z"
        />
        <g fillRule="evenodd" clipRule="evenodd">
            <path
                fill="#000"
                d="M23.856 7.213c.848 0 1.314.383 1.314 1.106.043.553-.339 1.02-.848 1.106l1.145 1.573h-.89L23.6 9.467h-.085v1.531h-.72V7.213zm-.34 1.744h.212c.467 0 .679-.213.679-.596 0-.34-.212-.553-.679-.553h-.212zm-3.223 2.041h2.035v-.638h-1.314V9.34h1.272V8.7h-1.272v-.85h1.314v-.638h-2.035zm-2.121-1.233-.976-2.552h-.764l1.57 3.87h.382l1.57-3.87h-.764zM9.56 9.127c0 1.063.848 1.956 1.909 1.956.339 0 .636-.085.933-.212v-.851c-.212.255-.51.425-.849.425-.678 0-1.23-.51-1.23-1.19v-.086c-.042-.68.51-1.276 1.188-1.318.34 0 .679.17.89.425v-.85c-.254-.17-.593-.213-.89-.213-1.103-.085-1.951.808-1.951 1.914m-1.315-.468c-.424-.17-.552-.255-.552-.468.043-.255.255-.468.51-.425.211 0 .424.127.593.297l.382-.51c-.297-.255-.679-.425-1.06-.425-.594-.043-1.103.425-1.146 1.02v.043c0 .51.212.808.891 1.02.17.043.34.128.51.214a.45.45 0 0 1 .211.382c0 .298-.254.553-.509.553h-.042a.84.84 0 0 1-.764-.51l-.466.468c.254.468.763.723 1.272.723.679.042 1.23-.468 1.273-1.149v-.127c-.043-.51-.255-.766-1.103-1.106m-2.46 2.34h.72V7.212h-.72zM2.435 7.212h1.272c1.018.042 1.824.893 1.782 1.914 0 .553-.255 1.063-.679 1.446-.382.298-.848.468-1.315.425h-1.06zm.933 3.147c.34.043.72-.085.975-.297.255-.256.382-.596.382-.979 0-.34-.127-.68-.382-.935a1.34 1.34 0 0 0-.975-.298h-.212v2.51z"
            />
            <path
                fill="#FD6020"
                d="M14.567 7.125c-1.061 0-1.952.85-1.952 1.957 0 1.063.849 1.956 1.951 1.999 1.103.042 1.952-.851 1.994-1.957-.042-1.106-.89-1.999-1.993-1.999"
            />
        </g>
    </svg>
)
const ForwardRef = forwardRef(SvgDiscover)
const Memo = memo(ForwardRef)
export default Memo
