import { useEffect, useRef } from "react"

function useGlobalEventListener(
    eventName: string,
    element: Document | Window,
    handler: (e: Event) => any,
    useCapture = false,
) {
    const savedCallback = useRef<(e: Event) => any>(() => {})

    useEffect(() => {
        savedCallback.current = handler
    }, [handler])

    useEffect(() => {
        // Make sure element supports addEventListener
        const isSupported = element && element.addEventListener
        if (!isSupported) {
            throw new Error("addEventListener not supported")
        }

        const eventListener = (event: Event) => savedCallback.current(event)

        element.addEventListener(eventName, eventListener, useCapture)

        return () => {
            element.removeEventListener(eventName, eventListener, useCapture)
        }
    }, [eventName, element, useCapture])
}

export { useGlobalEventListener }
