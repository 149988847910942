import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import purchasePageReducer, {
    purchasePageApi,
} from "./purchasePage/purchasePageSlice"
import noteReducer, { notesApi } from "./userList/notesSlice"
import userReducer from "./userSlice"

const rootReducer = combineReducers({
    [notesApi.reducerPath]: notesApi.reducer,
    [purchasePageApi.reducerPath]: purchasePageApi.reducer,
    purchasePage: purchasePageReducer,
    user: userReducer,
    notes: noteReducer,
})
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupStore = (preloadedState?: RootState) =>
    configureStore({
        reducer: rootReducer,
        preloadedState: preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat([
                notesApi.middleware,
                purchasePageApi.middleware,
            ]),
    })
export const store = setupStore(
    (window as any).$reactAppContext
        ? ({
              user: {
                  languageCode: (window as any).$reactAppContext.language_code,
                  loggedInUser: (window as any).$reactAppContext.logged_in_user,
              },
          } as RootState)
        : undefined,
)

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
