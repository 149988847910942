import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
    color?: string
}
const SvgCheckmark = (
    {
        title,
        titleId,
        color = "#222",
        ...props
    }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 16 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill={color}
            d="M4.743 11.846c.477.482 1.251.494 1.744.029l7.099-6.695a1.25 1.25 0 1 0-1.713-1.82L5.661 9.22 4.15 7.695a1.248 1.248 0 1 0-1.775 1.759z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgCheckmark)
const Memo = memo(ForwardRef)
export default Memo
