import React, { forwardRef } from "react"
import { Sizes } from "../../../../interfaces/sizing"
import { mergeClasses } from "../../../../utils/css"
import "./TextInput.scss"

interface TextInputProps extends React.ComponentPropsWithRef<"input"> {
    /**
     * A subset of the types supported by native `<input>` tags. The subset consists of the ones that are
     * single line text inputs, without any surprising styling concerns. Defaults to "text"
     */
    type?: "text" | "email" | "url" | "password" | "tel" | "number"
    /**
     * Apply any additional classes in addition to the ones provided by this component
     */
    className?: string
    /**
     * Defines the font size and line height
     */
    fontSize?: keyof typeof Sizes
}

/**
 * `<TextInput />` should be used in mostly the same way as `<input type="text">`. It provides some light
 * default styling, and some props to control sizing, and exposes a ref and all other input props so that
 * it can work with any form library.
 */
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    ({ type = "text", fontSize = Sizes.sm, className, ...props }, ref) => {
        return (
            <input
                className={mergeClasses(
                    "TextInput",
                    `TextInput--${fontSize}`,
                    className,
                )}
                type={type}
                ref={ref}
                {...props}
            />
        )
    },
)
