import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgBringfriend = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 19 20"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <circle
            cx={2.375}
            cy={2.375}
            r={2.375}
            fill="#033E58"
            transform="matrix(-1 0 0 1 18.208 5.75)"
        />
        <path fill="#033E58" d="M19 14.458a3.167 3.167 0 0 0-6.333 0V20H19z" />
        <path
            stroke="#033E58"
            strokeLinecap="round"
            strokeWidth={3}
            d="M14.25 13.27c-1.187-.395-3.167-1.187-4.75-5.145"
        />
        <path
            stroke="#E0EEFB"
            strokeLinecap="round"
            strokeWidth={3}
            d="M6.333 13.27c1.188-.395 3.167-1.187 4.75-5.145"
        />
        <circle cx={3.167} cy={8.125} r={2.375} fill="#033E58" />
        <path fill="#033E58" d="M0 14.458a3.167 3.167 0 0 1 6.333 0V20H0z" />
        <path
            stroke="#033E58"
            strokeLinecap="round"
            strokeWidth={3}
            d="M4.75 13.27c1.188-.395 3.167-1.187 4.75-5.145"
        />
        <path
            stroke="#033E58"
            strokeLinecap="round"
            d="m6.333 2.584 1.314 1.02M12.398 2.584l-1.314 1.02M9.5 1v1.583"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgBringfriend)
const Memo = memo(ForwardRef)
export default Memo
