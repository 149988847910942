import React, { useEffect, useState } from "react"

/**
 * State for a tooltip, provides `isOpen` as a boolean
 */
interface TooltipState {
    isOpen: boolean
}

/**
 * `useTooltip` is a react hook that manages the open and closed state of a tooltip element.
 *
 * @example
 * Here's a minimal example for usage.
 * ```jsx
 * const TooltipComponent = (props) => {
 *     const ref = useRef(null)
 *     const { isOpen } = useTooltip(ref)
 *
 *     return (
 *       <>
 *         <div ref={ref}>I have a tooltip!</div>
 *         {isOpen && <div class="tooltip">And I'm the tooltip that will appear on hover!</div>}
 *       </>
 *     )
 * }
 * ```
 *
 * @param ref A **required** reference to an html element that will have the event handlers attached to it.
 */
export function useTooltip(ref: React.RefObject<HTMLElement>): TooltipState {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handleEnter = () => {
        setIsOpen(true)
    }

    const handleLeave = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        const instance = ref.current

        instance?.addEventListener(`mouseenter`, handleEnter)
        instance?.addEventListener(`mouseleave`, handleLeave)

        return () => {
            instance?.removeEventListener(`mouseenter`, handleEnter)
            instance?.removeEventListener(`mouseleave`, handleLeave)
        }
    }, [ref])

    return {
        isOpen: isOpen,
    }
}
