import type React from "react"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { Weights } from "../../../interfaces/sizing"
import {
    type SavedCardPayMethod,
    useDeleteSavedCardMutation,
} from "../../../store/purchasePage/purchasePageSlice"
import { KeyboardEventHandlerChain } from "../../../utils/keyboardEventHandler"
import { ButtonColor, CancelConfirmButtonPair, Typography } from "../../common"
import { Overlay } from "../../common/atoms/Overlay"
import "./SavedCardDeleteModal.scss"
import { SavedCardLabel } from "../PurchaseRow/PurchaseRow"

const i18n = {
    header: gettext("Delete Card"),
    question: (cardNumber: string) =>
        interpolate(
            gettext(
                "Are you sure you want to delete this card ending in %(cardNumber)s from your account?",
            ),
            { cardNumber: cardNumber },
            true,
        ),
}

interface SavedCardDeleteModalProps {
    closeModal: () => void
    cardData: SavedCardPayMethod
}

export function SavedCardDeleteModal({
    closeModal,
    cardData,
    ...rest
}: SavedCardDeleteModalProps) {
    const [deleteCard] = useDeleteSavedCardMutation()
    const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation()
        handlers.execute(event)
    }

    const handlers = new KeyboardEventHandlerChain()
    handlers.addHandler({
        keyCode: 27, // Escape key
        requiresCtrlOrMeta: false,
        handle: () => {
            closeModal()
        },
    })

    const handleDeleteSavedCard = async (id: string) => {
        closeModal()
        await deleteCard({ id })
    }
    const overlayClickHandler = (
        // While this is _way_ more verbose than just duplicating the type, it means we use
        // the typing in `Overlay` as a single source of truth.
        event: Parameters<
            React.ComponentPropsWithoutRef<typeof Overlay>["onClick"]
        >[0],
    ) => {
        event.stopPropagation()
        closeModal()
    }

    return (
        <>
            <Overlay onClick={overlayClickHandler} />
            <div
                className="SavedCardDeleteModal"
                onKeyDown={keyDownHandler}
                {...rest}
            >
                <Typography
                    weight={Weights.bold}
                    className="SavedCardDeleteModal__header"
                >
                    {i18n.header}
                </Typography>
                <Typography size="smpx" className="SavedCardDeleteModal__text">
                    {i18n.question(cardData?.cc_last_4)}
                </Typography>
                <SavedCardLabel
                    last4={cardData.cc_last_4}
                    cardType={cardData.card_type}
                    expiresAt={cardData.cc_expire_string}
                    id={cardData.card_type}
                />
                <CancelConfirmButtonPair
                    cancelText="Cancel"
                    actionText="Delete"
                    actionColor={ButtonColor.Red}
                    cancelColor={ButtonColor.Link}
                    cancelClickHandler={closeModal}
                    actionClickHandler={() =>
                        void handleDeleteSavedCard(cardData.id)
                    }
                />
            </div>
        </>
    )
}
