import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgIDeal = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 604.4 526.98"
        width="1em"
        height="1em"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <defs>
            <style>{".iDeal_svg__cls-2{fill:#fff}"}</style>
        </defs>
        <g id="iDeal_svg__Layer_2" data-name="Layer 2">
            <g id="iDeal_svg__Layer_3" data-name="Layer 3">
                <path d="M67.18 296.62h96.85v160.82H67.18zM171.35 216.22a54.83 54.83 0 1 1-54.83-54.82 54.84 54.84 0 0 1 54.83 54.82" />
                <path d="M347.48 0H0v527h347.48v-.1c75.91-1 136-19.89 178.71-56.2 51.89-44.15 78.21-113.86 78.21-207.19 0-44.62-6.92-84.63-20.57-118.94-13.06-32.82-32.36-60.73-57.36-83-44-39.12-105.83-60.28-179-61.48Zm-6.57 35c67.34 0 123.47 18.25 162.31 52.77 43.91 39 66.18 98.14 66.18 175.72C569.4 417.24 494.66 492 340.91 492H35V35Z" />
                <path
                    d="M331.35 457.62H208.5v-380h117.89c102.45 0 211.51 40.43 211.51 190.5 0 158.66-109.06 189.51-211.51 189.51Z"
                    style={{
                        fill: "#cd0067",
                    }}
                />
                <path
                    d="M187.11 148h33.73q11.41 0 17.4 1.74a30.55 30.55 0 0 1 13.76 8.41 39.8 39.8 0 0 1 8.74 14.85q3 8.76 3 21.61a60.2 60.2 0 0 1-2.81 19.39 40.3 40.3 0 0 1-9.79 16.15 32.44 32.44 0 0 1-13 7.3q-6.1 1.94-16.33 1.93h-34.7Zm18.44 15.5v60.43h13.81a50.7 50.7 0 0 0 11.19-.87 17.1 17.1 0 0 0 7.47-3.79q3-2.69 4.84-8.81t1.88-16.71q0-10.59-1.88-16.25a20.9 20.9 0 0 0-5.25-8.84 17.6 17.6 0 0 0-8.56-4.29q-3.88-.87-15.19-.87Z"
                    className="iDeal_svg__cls-2"
                />
                <path
                    d="M184.61 145.49v96.43h37.23c7 0 12.79-.69 17.09-2a35 35 0 0 0 14-7.89 42.9 42.9 0 0 0 10.4-17.13 62.6 62.6 0 0 0 2.95-20.27c0-8.8-1.06-16.35-3.13-22.42a42.7 42.7 0 0 0-9.28-15.73 33.2 33.2 0 0 0-14.89-9.1c-4.26-1.24-10.18-1.84-18.1-1.84Zm36.23 5c7.33 0 12.95.55 16.7 1.64a28.3 28.3 0 0 1 12.66 7.74 37.6 37.6 0 0 1 8.18 13.9c1.89 5.56 2.86 12.56 2.86 20.81a58 58 0 0 1-2.67 18.65 38.06 38.06 0 0 1-9.17 15.17 30.2 30.2 0 0 1-12 6.71c-3.8 1.2-9 1.81-15.57 1.81h-32.22v-86.43Z"
                    className="iDeal_svg__cls-2"
                />
                <path
                    d="M203.05 161v65.43h16.31a52.7 52.7 0 0 0 11.8-.94 19.7 19.7 0 0 0 8.53-4.37c2.35-2.11 4.16-5.36 5.56-9.93 1.34-4.37 2-10.07 2-17.44s-.66-13-2-17a23.34 23.34 0 0 0-5.91-9.88 20.23 20.23 0 0 0-9.74-4.92c-2.78-.62-7.92-.92-15.72-.92Zm10.81 5c9.12 0 13 .44 14.64.81a15.3 15.3 0 0 1 7.4 3.67 18.6 18.6 0 0 1 4.59 7.81c1.16 3.5 1.75 8.7 1.75 15.46s-.6 12.15-1.77 16c-1.11 3.62-2.5 6.21-4.13 7.68a14.7 14.7 0 0 1-6.4 3.23 48.8 48.8 0 0 1-10.58.79h-11.31V166ZM284.6 239.42V148h67.68v15.5H303v20.25h45.82v15.5H303v24.68h51v15.5Z"
                    className="iDeal_svg__cls-2"
                />
                <path
                    d="M282.1 145.49v96.43h74.43v-20.5h-51v-19.68h45.82v-20.5h-45.82V166h49.25v-20.5Zm67.68 5V161h-49.25v25.25h45.82v10.5h-45.82v29.68h51v10.5H287.1v-86.44ZM457.29 239.42H437.2l-8-20.75h-36.51l-7.57 20.75h-19.58L401.07 148h19.65Zm-34-36.25-12.6-33.87-12.35 33.87Z"
                    className="iDeal_svg__cls-2"
                />
                <path
                    d="m399.36 145.49-37.48 96.43h25s6.56-18 7.57-20.75h33.07c1 2.73 8 20.75 8 20.75H461l-38.57-96.43Zm19.67 5c1.17 2.92 32.07 80.19 34.56 86.43h-14.67l-8-20.75h-40l-7.56 20.75h-14.17l33.59-86.43Z"
                    className="iDeal_svg__cls-2"
                />
                <path
                    d="M394.77 205.67h32.12l-16.22-43.6Zm16-29.14 9 24.14h-17.86c1.58-4.31 6.09-16.76 8.81-24.14ZM472.71 239.42v-90.68h18.44v75.18h45.94v15.5Z"
                    className="iDeal_svg__cls-2"
                />
                <path
                    d="M470.21 146.24v95.68h69.38v-20.5h-45.94v-75.18Zm18.44 5v75.18h45.94v10.5h-59.38v-85.68Z"
                    className="iDeal_svg__cls-2"
                />
            </g>
        </g>
    </svg>
)
const ForwardRef = forwardRef(SvgIDeal)
const Memo = memo(ForwardRef)
export default Memo
