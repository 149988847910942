import { type ComponentPropsWithoutRef, useState } from "react"
import "./InputCopy.scss"
import Button from "../../atoms/Button"

interface InputCopyProps extends ComponentPropsWithoutRef<"div"> {
    text: string
}

export function InputCopy({ text, ...props }: InputCopyProps) {
    const [textToShow, setTextToShow] = useState<string>("Copy")

    const handleCopy = async (): Promise<void> => {
        try {
            await navigator.clipboard.writeText(text)
            setTextToShow("Copied")
            setTimeout(() => setTextToShow("Copy"), 1000)
        } catch (err) {}
    }

    return (
        <div className="InputCopy" {...props}>
            <div className="InputCopy__text">{text}</div>
            <Button
                className="InputCopy__copyButton"
                text={textToShow}
                onClick={handleCopy}
            />
        </div>
    )
}
