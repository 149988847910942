import type { ComponentPropsWithoutRef } from "react"
import Button, { ButtonColor } from "../../atoms/Button"
import { List } from "../../atoms/List"
import "./ShowMoreList.scss"

interface ShowMoreListProps extends ComponentPropsWithoutRef<"div"> {
    showMoreClicked: boolean
    onShowMoreClicked: () => void
    /** Text to display on the show more button */
    showMoreText: string
    /** Filter function based on which just show initial list before show more clicked */
    initialShowMoreFilter: (opt: Record<string, any>) => boolean
    options: { id: string; label: React.ReactNode }[]
    /** ReactNode to render when show more button clicked */
    renderAfterShowMoreClicked?: React.ReactNode
}
export function ShowMoreList({
    showMoreClicked,
    onShowMoreClicked,
    showMoreText,
    options,
    initialShowMoreFilter,
    renderAfterShowMoreClicked,
    ...props
}: ShowMoreListProps) {
    const opts = showMoreClicked
        ? options
        : options.filter(initialShowMoreFilter)

    const shouldShowMoreButton =
        options.length > opts.length && !showMoreClicked

    return (
        <div className="cb-showmore-list" {...props}>
            <List options={opts} />
            {shouldShowMoreButton && (
                <div className="cb-showmore-list__showMoreWrapper">
                    <Button
                        color={ButtonColor.Link}
                        text={showMoreText}
                        onClick={onShowMoreClicked}
                    />
                </div>
            )}
            {Boolean(renderAfterShowMoreClicked) &&
                showMoreClicked &&
                renderAfterShowMoreClicked}
        </div>
    )
}
