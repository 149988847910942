import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgEpoch = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 105 27"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#343434"
            fillRule="evenodd"
            d="M88.5.5h2v4h7a7 7 0 0 1 7 7V22h-2V11.5a5 5 0 0 0-5-5h-7V22h-2zm-35.25 6a6.75 6.75 0 0 0-6.75 6.75v1a6.75 6.75 0 0 0 13.5 0v-1a6.75 6.75 0 0 0-6.75-6.75m-8.75 6.75a8.75 8.75 0 1 1 17.5 0v1a8.75 8.75 0 1 1-17.5 0zM4.5 5.5a4 4 0 0 0-4 4V17a5 5 0 0 0 5 5H17v-2H5.5a3 3 0 0 1-3-3v-1.5H12a5 5 0 0 0 0-10zm7.5 8H2.5v-4a2 2 0 0 1 2-2H12a3 3 0 1 1 0 6m20.5-8h-10V27h2v-6h8a7 7 0 0 0 7-7v-1.5a7 7 0 0 0-7-7m0 13.5h-8V7.5h8a5 5 0 0 1 5 5V14a5 5 0 0 1-5 5M74 5a7 7 0 0 0-7 7v3.5a7 7 0 0 0 7 7h8.5v-2H74a5 5 0 0 1-5-5V12a5 5 0 0 1 5-5h8.5V5z"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgEpoch)
const Memo = memo(ForwardRef)
export default Memo
