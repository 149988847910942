import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgEtherium = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 19 19"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <circle cx={9.499} cy={9.5} r={9.5} fill="#6481E7" />
        <path
            fill="#C1CCF5"
            fillRule="evenodd"
            d="M9.61 2.816v9.59l4.262-2.52z"
            clipRule="evenodd"
        />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="m5.349 9.887 4.261 2.519v-9.59z"
            clipRule="evenodd"
        />
        <path
            fill="#8299EC"
            fillRule="evenodd"
            d="M9.61 7.95 5.35 9.886l4.261 2.52 4.262-2.52z"
            clipRule="evenodd"
        />
        <path
            fill="#C1CCF5"
            fillRule="evenodd"
            d="M9.61 13.213V16.7l4.265-6.005zM9.61 7.95 5.35 9.886l4.261 2.52z"
            clipRule="evenodd"
        />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M5.349 10.695 9.61 16.7v-3.487z"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgEtherium)
const Memo = memo(ForwardRef)
export default Memo
