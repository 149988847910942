import {
    genericDefaultPaymethodMapping,
    PAYMETHOD_CREDITCARD,
    PAYMETHOD_CREDITCARDEPOCH,
    PAYMETHOD_DPMAX,
    PAYMETHOD_EPOCH_ONECLICK,
    PAYMETHOD_IDEAL,
    PAYMETHOD_JCB,
    PAYMETHOD_PAYPAL_EPOCH_ONECLICK,
    PAYMETHOD_PAYPALEPOCH,
    PAYMETHOD_PAYSAFECARD,
    PAYMETHOD_REFER_FRIEND,
    PAYMETHOD_SAFETYPAY,
    PAYMETHOD_SAVECARD,
    PAYMETHOD_SOFORT,
    PAYMETHOD_WIRE,
    purchaseMethodsMappings,
} from "./constants"
import type { PurchaseMethodMappingType } from "./types"
import type { PaymentPlanKeys } from "../../store/purchasePage/purchasePageSlice"

export const shouldShowTokenPlans = (paymethod: string): boolean => {
    return [
        PAYMETHOD_CREDITCARD,
        PAYMETHOD_PAYPALEPOCH,
        PAYMETHOD_CREDITCARDEPOCH,
        PAYMETHOD_IDEAL,
        PAYMETHOD_SOFORT,
        PAYMETHOD_PAYSAFECARD,
        PAYMETHOD_SAFETYPAY,
        PAYMETHOD_JCB,
        PAYMETHOD_DPMAX,
        PAYMETHOD_SAVECARD,
        PAYMETHOD_EPOCH_ONECLICK,
        PAYMETHOD_PAYPAL_EPOCH_ONECLICK,
    ].includes(paymethod)
}

export const isAffectedBySpendingLimit = (paymethod: string): boolean => {
    return [
        PAYMETHOD_PAYPALEPOCH,
        PAYMETHOD_CREDITCARDEPOCH,
        PAYMETHOD_CREDITCARD,
        PAYMETHOD_EPOCH_ONECLICK,
        PAYMETHOD_SAVECARD,
    ].includes(paymethod)
}

export const getPayMethodMappings = (
    payMethod: string,
): PurchaseMethodMappingType => {
    return (
        purchaseMethodsMappings[payMethod] ??
        genericDefaultPaymethodMapping(payMethod)
    )
}

export const shouldShowCheckoutButton = (paymethod: string): boolean =>
    ![PAYMETHOD_WIRE, PAYMETHOD_REFER_FRIEND].includes(paymethod)

export const isAlternativePaymethod = (paymethod: string): boolean =>
    [
        PAYMETHOD_IDEAL,
        PAYMETHOD_SOFORT,
        PAYMETHOD_PAYSAFECARD,
        PAYMETHOD_SAFETYPAY,
        PAYMETHOD_JCB,
        PAYMETHOD_DPMAX,
    ].includes(paymethod)

export const getProperPaymentPlans = (
    payMethod: string,
): PaymentPlanKeys | void => {
    if (!shouldShowTokenPlans(payMethod)) {
        return
    }
    switch (payMethod) {
        case PAYMETHOD_CREDITCARD:
            return "creditCard"
        case PAYMETHOD_SAVECARD:
            return "creditCard"
        case PAYMETHOD_PAYPALEPOCH:
            return "paypal"
        case PAYMETHOD_CREDITCARDEPOCH:
            return "epoch"
        case PAYMETHOD_EPOCH_ONECLICK:
            return "epoch"
        case PAYMETHOD_PAYPAL_EPOCH_ONECLICK:
            return "paypal"
        default:
            return "alternative"
    }
}
