import "./DivotTooltip.scss"

export interface DivotTooltipProps {
    direction: "right" | "left" | "top" | "bottom"
    top?: string
    left?: string
    right?: string
    bottom?: string
    divotBgTop?: string
    divotBgLeft?: string
}

export function DivotTooltip({
    direction = "left",
    top = "auto",
    left = "auto",
    right = "auto",
    bottom = "auto",
    divotBgTop = "auto",
    divotBgLeft = "auto",
}: DivotTooltipProps) {
    const style = {
        "--direction": direction,
        "--divot-top": top,
        "--divot-left": left,
        "--divot-right": right,
        "--divot-bottom": bottom,
        "--divot-bg-top": divotBgTop,
        "--divot-bg-left": divotBgLeft,
    } as React.CSSProperties
    const divotClass = `Tooltip__divot ${direction}`

    return (
        <div className={divotClass} style={style}>
            <div className="divotBorder">
                <div className="divotBackground" />
            </div>
        </div>
    )
}
