import type React from "react"

import { mergeClasses } from "../../../../utils/css"
import "./List.scss"

interface ListProps {
    options: { id: string; label: React.ReactNode }[]
    className?: string
}

export const List = ({ className, options, ...props }: ListProps) => (
    <ul className={mergeClasses("cb-list", className)} {...props}>
        {options.map((option) => {
            return <li key={option.id}>{option.label}</li>
        })}
    </ul>
)
