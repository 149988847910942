import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgWire = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 28 19"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <g fill="#033E58">
            <path d="M2.737 12.823c.252.266.676.25.946-.034l.61-.643c.068-.071.174-.075.237-.009l4.611 4.856c.378.398 1.013.374 1.418-.052s.427-1.095.049-1.493l-4.612-4.855c-.063-.066-.059-.178.009-.249l.61-.643a.76.76 0 0 0 .194-.692.62.62 0 0 0-.466-.477l-3.747-.858a.68.68 0 0 0-.64.208.77.77 0 0 0-.199.675l.815 3.946a.66.66 0 0 0 .165.32M26.242 10.443l-.814-3.946a.63.63 0 0 0-.454-.49.68.68 0 0 0-.657.204l-.61.644c-.068.07-.174.074-.237.008l-4.612-4.855c-.378-.398-1.012-.375-1.417.051s-.427 1.095-.049 1.493l4.611 4.855c.03.032.047.076.046.123a.2.2 0 0 1-.054.126l-.61.643a.76.76 0 0 0-.195.692.62.62 0 0 0 .467.477l3.747.858a.68.68 0 0 0 .64-.208.77.77 0 0 0 .198-.675M13.96 12.005h-1.977a.91.91 0 0 0-.922.896.91.91 0 0 0 .922.896h.23c.128 0 .23.1.23.224v.224a.91.91 0 0 0 .923.896.91.91 0 0 0 .921-.896v-.29c0-.107.077-.199.185-.22 1.102-.209 1.944-1.078 2.095-2.16.15-1.081-.425-2.136-1.43-2.622l-1.797-.874a.76.76 0 0 1-.415-.86.78.78 0 0 1 .767-.59h1.978a.91.91 0 0 0 .922-.895.91.91 0 0 0-.922-.896h-.23c-.128 0-.231-.1-.231-.224V4.39a.91.91 0 0 0-.922-.896.91.91 0 0 0-.921.896v.29a.225.225 0 0 1-.185.219c-1.102.21-1.945 1.078-2.095 2.16-.151 1.081.424 2.136 1.43 2.623l1.796.873a.76.76 0 0 1 .415.86.78.78 0 0 1-.766.59" />
        </g>
    </svg>
)
const ForwardRef = forwardRef(SvgWire)
const Memo = memo(ForwardRef)
export default Memo
