import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgPaygarden = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 30 21"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <g stroke="#E0EEFB">
            <rect
                width={29}
                height={20}
                x={0.5}
                y={0.5}
                fill="#033E58"
                rx={3}
            />
            <path
                fill="#306A91"
                strokeWidth={0.594}
                d="M1.297 9.609h27.406v.594H1.297z"
            />
            <g strokeLinecap="round">
                <path d="M7.918 8.552C7.66 7.278 6.433 4.556 4.837 6.004c-1.684 1.528 2.696 3.822 3.08 2.548Zm0 0c-1.156.99-2.61 3.73-3.466 4.671M8.081 8.552c.257-1.274 1.485-3.996 3.081-2.548 1.684 1.528-2.696 3.822-3.08 2.548Zm0 0c1.156.99 2.073 3.136 3.466 4.671" />
            </g>
            <path
                fill="#306A91"
                strokeWidth={0.538}
                d="M8.27 1.27V19.73H7.73V1.27z"
            />
        </g>
    </svg>
)
const ForwardRef = forwardRef(SvgPaygarden)
const Memo = memo(ForwardRef)
export default Memo
