import type { SyntheticEvent } from "react"
import type React from "react"
import "./Overlay.scss"

interface OverlayProps extends React.ComponentPropsWithoutRef<"div"> {
    /**
     * Click handler for when the overlay is interacted with.
     */
    onClick: (event: SyntheticEvent<HTMLDivElement, MouseEvent>) => void
}

/**
 * The overlay component currently renders nothing visible, but takes up the entire area of the page.
 * It's useful when you're displaying a dialog to a user and need to prevent interaction behind the dialog.
 */
export function Overlay(props: OverlayProps) {
    const { onClick, ...rest } = props
    return (
        <div
            className="Overlay"
            onClick={onClick}
            data-testid="Overlay"
            {...rest}
        />
    )
}
