import { createSlice } from "@reduxjs/toolkit"

interface User {
    username: string
}

export interface UserState {
    languageCode: string
    loggedInUser?: User
}

const initialState: UserState = {
    languageCode: "en", // this should be overwritten by the preloadedState in store.ts
}

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {},
})

export default userSlice.reducer
