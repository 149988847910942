import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgBitcoin = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 19 19"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#F7931A"
            fillRule="evenodd"
            d="M19 9.5a9.5 9.5 0 1 1-19 0 9.5 9.5 0 1 1 19 0M11.78 5.97c1.322.454 2.289 1.134 2.099 2.398-.138.926-.653 1.374-1.338 1.53.94.488 1.26 1.412.962 2.53-.564 1.608-1.907 1.744-3.692 1.408l-.434 1.729-1.046-.26.427-1.706a44 44 0 0 1-.834-.216l-.43 1.714-1.044-.26.432-1.732-2.107-.53.52-1.194s.771.204.76.189c.297.073.429-.12.48-.247L7.711 6.63c.013-.222-.064-.501-.488-.607.016-.011-.76-.188-.76-.188l.279-1.114 2.11.521.43-1.713 1.046.26-.42 1.68c.28.063.564.128.838.196l.418-1.668 1.046.26zM9.278 8.828c.713.189 2.265.6 2.535-.478.276-1.103-1.231-1.437-1.97-1.6l-.217-.05-.52 2.083zm-.807 3.353c.854.225 2.721.717 3.018-.472.304-1.215-1.506-1.62-2.39-1.817l-.257-.059-.575 2.295z"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgBitcoin)
const Memo = memo(ForwardRef)
export default Memo
