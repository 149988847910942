import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgInfo = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 14 14"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#306A91"
            d="M7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0m1.457 10.849q-.54.213-.862.324a2.3 2.3 0 0 1-.748.112q-.655 0-1.018-.32a1.03 1.03 0 0 1-.362-.81q0-.19.027-.39.027-.198.087-.45l.451-1.592q.06-.23.101-.434a2 2 0 0 0 .04-.375q0-.304-.125-.425-.126-.12-.482-.119-.175 0-.358.054a5 5 0 0 0-.314.104l.12-.49q.441-.181.847-.31.404-.129.764-.129.65.001 1.003.314.352.315.352.816 0 .104-.024.366a2.5 2.5 0 0 1-.09.48l-.45 1.588q-.053.192-.098.436a2.3 2.3 0 0 0-.043.371q0 .316.141.432.142.114.49.115.164 0 .371-.058a2 2 0 0 0 .3-.1zm-.08-6.447a1.07 1.07 0 0 1-.755.292q-.442 0-.759-.292a.93.93 0 0 1-.315-.707.94.94 0 0 1 .315-.708q.317-.295.759-.295t.755.295.315.708a.93.93 0 0 1-.315.707"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgInfo)
const Memo = memo(ForwardRef)
export default Memo
